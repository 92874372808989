const getSvg = (width, text) =>
  `<svg xmlns='http://www.w3.org/2000/svg' height='34px' width='${width}px'>` +
  `<circle cx='9' cy='17' r='2.5' fill='white'/>` +
  `<text x='30' y='22' fill='white' font-size='15' font-family='Arial,sans-serif' font-weight='500'>${text}</text>` +
  `</svg>`;

const addToplineClassToNavigation = () => {
  const navbar = document.getElementById("toggleNavbar");

  if (navbar) {
    navbar.classList.add("toggleable-nav--with-top-line");
  }
};

const addHeaderToplineBackground = (topLine) => {
  const width = topLine.querySelector(".header-top-line__wrapper").offsetWidth;
  const text = topLine
    .querySelector(".header-top-line__title")
    .innerHTML.replace(/"/, "&quote;");

  const style = document.createElement("style");
  style.appendChild(
    document.createTextNode(`.header-top-line__before,.header-top-line__after {
            background-image: url("data:image/svg+xml;utf8,${getSvg(
              width,
              text
            )}");
        }`)
  );

  topLine.after(style);
};

(() => {
  const topLine = document.querySelector(".header-top-line");

  if (topLine) {
    addToplineClassToNavigation();
    addHeaderToplineBackground(topLine);
  }
})();
